import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import Alert from 'react-s-alert'
import { theme, GlobalStyles, space } from 'theme'
import SEO from 'components/SEO'
import Header from 'components/Header'
import CookieNotice from 'components/CookieNotice'
import AlertBar from 'components/AlertBar'
import AlertTemplate from 'components/AlertTemplate'
import favicon16 from 'static/images/favicon16.png'
import favicon32 from 'static/images/favicon32.png'
import favicon64 from 'static/images/favicon64.png'
import 'sanitize.css'
import 'fonts.css'
import '@reach/dialog/styles.css'
import 'rc-slider/assets/index.css'
import 'react-s-alert/dist/s-alert-default.css'
import { pxToRem } from 'helpers'

const COOKIE_NOTICE_OFFSET = 25

const Wrapper = styled.div`
  padding-bottom: ${COOKIE_NOTICE_OFFSET}px;
`

const BodyWrapper = styled.div`
  padding-top: ${({ isHeaderVisible }) => (isHeaderVisible ? `calc(${pxToRem(50)} + 2 * ${space.m})` : `0`)};
`

const App = ({ isHeaderVisible, fullHeader = false, children, ...props }) => {
  const [isAlertBarOpen, setIsAlertBarOpen] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <SEO />
        <Helmet>
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="shortcut icon" type="image/png" href={favicon64} />
        </Helmet>
        <GlobalStyles />
        {isHeaderVisible && <Header categories={props.categories} />}
        <BodyWrapper isHeaderVisible={isHeaderVisible}>{children}</BodyWrapper>
        <CookieNotice isAlertBarOpen={isAlertBarOpen} />
        <AlertBar setIsAlertBarOpen={setIsAlertBarOpen} />
        <Alert stack={{ limit: 3 }} contentTemplate={AlertTemplate} position="top-right" />
      </Wrapper>
    </ThemeProvider>
  )
}

App.defaultProps = {
  isHeaderVisible: true,
  categories: [],
}

App.propTypes = {
  isHeaderVisible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  fullHeader: PropTypes.bool,
  categories: PropTypes.array,
}

export default App
