/* eslint-disable camelcase */
import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Cookies from 'js-cookie'
import { space, colors, COLOR_CONSTANTS, radius } from 'theme'
import {
  CONTAINER_MAX_WIDTH,
  SITE_LINK_PUBLISHING,
  SITE_LINK_ANALYTICS,
  SITE_LINK_LISTENING,
  SITE_LINK_REVIEWS,
  SITE_LINK_VISTA_PAGE,
  SITE_LINK_ENGAGEMENT,
  SITE_LINK_INTEGRATIONS,
  SITE_LINK_ADVOCACY,
  SITE_LINK_SOLUTIONS_AGENCIES,
  SITE_LINK_SOLUTIONS_CREATORS,
  SITE_LINK_SOLUTIONS_SMALL_BUSINESSES,
  SITE_LINK_SOLUTIONS_ENTERPRISE,
  SITE_LINK_SOLUTIONS_MULTI_LOCATION_BRANDS,
} from 'consts'
import { pxToRem, getTimeZone } from 'helpers'
import LocalImage from 'components/LocalImage'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'
import imageDoubleArrowRightWhite from 'static/images/home/double_arrow_right_white.svg'
import networkImageInstagram from 'static/images/networks/instagram.svg'
import networkImageFacebook from 'static/images/networks/facebook.svg'
import networkImageTwitter from 'static/images/networks/x2.svg'
import networkImageLinkedin from 'static/images/networks/linkedin.svg'
import networkImageTikTok from 'static/images/networks/tiktok.svg'
import networkImageThreads from 'static/images/networks/threads2.svg'
import networkImageCross from 'static/images/networks/cross.svg'

import solutionsImageAgency from 'static/images/solutions/agency.svg'
import solutionsImageCreators from 'static/images/solutions/creators.svg'
import solutionsImageEnterprise from 'static/images/solutions/enterprise.svg'
import solutionsImageMultiLocationBrands from 'static/images/solutions/multi_location_brands.svg'
import solutionsImageSmallBusinesses from 'static/images/solutions/small_businesses.svg'

import { Flex } from './Layout'
import { Text } from './Typography'
import Button from './Button'
import GoogleSignupPopup from './GoogleSignupPopup'
import BlogSearcherComponent from './BlogSearcherComponent'
import logoBig from '../static/svg/logo_big.svg'
import hamburger from '../static/svg/hamburger.svg'
import DropdownMenu from './DropdownMenu'
import PublishIcon from '../static/svg/paper_plane.svg'
import AnalyticsIcon from '../static/svg/report.svg'
import ReviewsIcon from '../static/svg/review.svg'
import VistaPageIcon from '../static/svg/vistapage.svg'
import ListeningIcon from '../static/svg/radio.svg'
import EngagementIcon from '../static/svg/inbox.svg'
import ChevronRightSalute from '../static/svg/chevron_right_salute.svg'
import ChevronRightCobalt from '../static/svg/chevron_right_cobalt.svg'
import VistaAdvocacyIcon from '../static/svg/advocacy.svg'

const StyledFlex = styled(Flex)`
  position: fixed;
  top: ${({ top }) => top || 0};
  background: ${COLOR_CONSTANTS.WHITE};
  width: 100%;
  z-index: 10;
  height: 70px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
  ${({ isVisible }) => (isVisible ? 'transform: translateY(0);' : 'transform: translateY(-100%);')}
`

const HomeLink = styled.a`
  text-decoration: none;
  color: ${colors.primaryText};

  &:visited {
    color: initial;
  }
`

const MenuLinkWrapper = styled(Flex)`
  position: relative;
  height: 100%;
  align-items: center;

  div {
    visibility: hidden;
  }

  &:hover {
    color: ${colors.primary};

    p {
      color: ${colors.primary};
    }

    div {
      visibility: visible;
    }

    img.arrow_down_salute {
      display: none;
    }

    img.arrow_down_cobalt {
      display: block;
    }
  }
`

const StyledImageChevronRightSalute = styled(LocalImage)`
  animation: rotate-back 0.3s linear 0s both;
  @keyframes rotate-back {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }
`

const StyledImageChevronRightCobalt = styled(LocalImage)`
  display: none;
  animation: rotate 0.3s linear 0s both;
  @keyframes rotate {
    0% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }
`

const StyledLink = styled(Text)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ color }) => color || colors.primaryText};
  text-decoration: none;

  &:hover {
    color: ${colors.primary};

    p {
      color: ${colors.primary};
    }
  }
`

const MenuChildrenStyledLink = styled(StyledLink)`
  &:hover {
    background: ${COLOR_CONSTANTS.SALT};
  }
`

const MenuChildrenWrapper = styled(Flex)`
  z-index: 1;
  position: absolute;
  top: 100%;
  flex-direction: column;
  width: max-content;
  min-width: 210px;
  max-width: 300px;
  padding: 0;
  box-shadow: 0 4px 20px rgba(39, 40, 49, 0.1);
  background-color: ${colors.white};
  border: 1px solid ${COLOR_CONSTANTS.SALT};
  border-radius: ${radius.l};
  left: ${space.m};
  transition: visibility 0ms ease-in-out 10ms, z-index 0ms ease-in-out 10ms, opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
`

const ButtonLinkDropdownMenu = styled(Button.Gradient)`
  justify-content: flex-start;
  min-height: 50px;
`

const StyledButtonGradient = styled(Button.Gradient)`
  padding-left: ${space.l};
  padding-right: ${space.l};
  margin-left: ${space.s};
`

const StyledImageArrowBlue = styled(LocalImage)`
  display: none;
  animation: float-right 1s linear 0s infinite both;
  @keyframes float-right {
    0% {
      transform: translateY(0) translateX(0);
    }
    50% {
      transform: translateY(0) translateX(${space.xs});
    }
    100% {
      transform: translateY(0) translateX(0);
    }
  }
`

const StyledContinueButton = styled(Button.Gradient)`
  width: ${({ width }) => width};
  height: ${({ height }) => height || pxToRem(40)};

  &:hover {
    img.arrow_white {
      display: none;
    }

    img.arrow_blue {
      display: block;
    }
  }
`

const StyledContinueText = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: ${({ width }) => width};
`

const MENU_LINKS = [
  {
    label: 'Features',
    to: SITE_LINK_PUBLISHING,
    children: [
      {
        label: 'Publishing',
        to: SITE_LINK_PUBLISHING,
        icon: PublishIcon,
        description: 'Plan, collaborate and publish social content. Now powered by ChatGPT!',
      },
      {
        label: 'Engagement',
        to: SITE_LINK_ENGAGEMENT,
        icon: EngagementIcon,
        description: 'Easily manage comments and messages with a unified social inbox.',
      },

      {
        label: 'Analytics',
        to: SITE_LINK_ANALYTICS,
        icon: AnalyticsIcon,
        description: 'Analyze social media performance and create reports.',
      },
      {
        label: 'Listening',
        to: SITE_LINK_LISTENING,
        icon: ListeningIcon,
        description: 'Discover social conversations to better inform marketing strategy.',
      },
      {
        label: 'Reputation',
        to: SITE_LINK_REVIEWS,
        icon: ReviewsIcon,
        description: 'Manage reviews across the sites that matter to your brand.',
      },
      {
        label: 'Vista Page',
        to: SITE_LINK_VISTA_PAGE,
        icon: VistaPageIcon,
        description: 'Link in bio, micro site and landing pages for your business.',
      },
      {
        label: 'Employee Advocacy',
        to: SITE_LINK_ADVOCACY,
        icon: VistaAdvocacyIcon,
        description: 'Amplify your social message with an employee advocacy program.',
      },
    ],
  },
  {
    label: 'Solutions',
    to: SITE_LINK_SOLUTIONS_AGENCIES,
    children: [
      {
        label: 'Agencies & Freelancers',
        to: SITE_LINK_SOLUTIONS_AGENCIES,
        description: 'Manage social for all your clients. Publish, collaborate, and deliver stunning reports.',
        icon: solutionsImageAgency,
      },
      {
        label: 'Creators & Influencers',
        to: SITE_LINK_SOLUTIONS_CREATORS,
        description: 'Manage your social content, engage, get noticed by brands for collaborations.',
        icon: solutionsImageCreators,
      },
      // TODO:: CHANGE TEXT HERE
      {
        label: 'Enterprise',
        to: SITE_LINK_SOLUTIONS_ENTERPRISE,
        description: 'Large organizations with social media managagement needs beyond our standard plans.',
        icon: solutionsImageEnterprise,
      },
      // TODO:: CHANGE TEXT HERE
      {
        label: 'Multi-Location Brands',
        to: SITE_LINK_SOLUTIONS_MULTI_LOCATION_BRANDS,
        description: 'Create content across your locations, ensure consistent brand voice and style.',
        icon: solutionsImageMultiLocationBrands,
      },
      {
        label: 'Small & Medium Businesses',
        to: SITE_LINK_SOLUTIONS_SMALL_BUSINESSES,
        description: 'Simplify social and build relationships with followers using best-in-class tools.',
        icon: solutionsImageSmallBusinesses,
      },
    ],
  },

  {
    label: 'Channels',
    to: SITE_LINK_INTEGRATIONS,
    children: [
      {
        label: 'Facebook',
        to: `${SITE_LINK_INTEGRATIONS}facebook`,
        icon: networkImageFacebook,
        description: `Tools for Pages and Groups`,
      },
      {
        label: 'Instagram',
        to: `${SITE_LINK_INTEGRATIONS}instagram`,
        icon: networkImageInstagram,
        description: `Tools for Instagram Business Profiles`,
      },

      {
        label: 'LinkedIn',
        to: `${SITE_LINK_INTEGRATIONS}linkedin`,
        icon: networkImageLinkedin,
        description: `Pages and Personal Profiles`,
      },

      {
        label: 'TikTok',
        to: `${SITE_LINK_INTEGRATIONS}tiktok`,
        icon: networkImageTikTok,
        description: `TikTok Business and Creator Profiles`,
      },
      {
        label: 'Threads',
        to: `${SITE_LINK_INTEGRATIONS}threads`,
        icon: networkImageThreads,
        description: `Tools for Thread Profiles`,
      },
      {
        label: 'X (Twitter)',
        to: `${SITE_LINK_INTEGRATIONS}twitter`,
        icon: networkImageTwitter,
        description: `Tools for X Profiles`,
      },
      {
        label: 'All integrations',
        to: SITE_LINK_INTEGRATIONS,
        icon: networkImageCross,
        description: `Complete list of integrations`,
      },
    ],
  },
  {
    label: 'Pricing',
    to: '/pricing',
  },
  // {
  //   label: 'Customers',
  //   to: SITE_LINK_CUSTOMER_STORIES,
  // },
  {
    label: 'Blog',
    to: '/insights/',
  },
  {
    label: 'Podcast',
    to: '/beyond-social-podcast/',
  },
]

const DEFAULT_MENU_LINK_HEIGHT = '50px'

const Header = () => {
  const [loggedInInformation, setLoggedInInformation] = useState({ loggedIn: false, userName: '' })
  const [headerOptions, setHeaderOptions] = useState({ isVisible: true, scroll: 0, defaultScroll: 300 })
  const [showGoogleSignedUpPopup, setShowGoogleSignedUpPopup] = useState(false)
  const [isSearchShown, setIsSearchShown] = useState(false)

  const { loggedIn, userName } = loggedInInformation

  const getLoggedInUser = async () => {
    try {
      const response = await fetch(`${process.env.GATSBY_PUBLIC_GATSBY_API_URL}/status`)
      const data = await response.json()

      const { loggedIn } = data || {}

      setLoggedInInformation({ ...data })

      if (!loggedIn) {
        let tries = 0

        const launchOneTap = () => {
          if (++tries >= 20) {
            return
          }
          setTimeout(() => {
            if (typeof google !== 'undefined') {
              const onOneTapSignedIn = async (response) => {
                response.page = window.location.pathname
                response.timezone = getTimeZone()

                const res = await fetch(
                  `${process.env.GATSBY_PUBLIC_GATSBY_API_URL}/connect/google/credentials?visitor=true`,
                  {
                    method: 'POST',
                    body: JSON.stringify(response),
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                  }
                )

                const res_data = await res.json()

                // console.log('res_data', res_data)

                if (res_data.token) {
                  await Cookies.set('jwt', res_data.token)
                  if (!res_data.hasAccount) {
                    setShowGoogleSignedUpPopup(true)
                  } else {
                    setLoggedInInformation({ ...{ loggedIn: true, userName: res_data.userName } })
                  }
                }
              }

              google.accounts.id.initialize({
                client_id: process.env.GATSBY_PUBLIC_GOOGLE_CLIENT_ID,
                callback: onOneTapSignedIn,
              })

              google.accounts.id.prompt((notification) => {
                // console.log('notification', notification)
                if (notification.isDisplayed()) {
                  // console.log('showing google login prompt')
                } else if (notification.isNotDisplayed()) {
                  console.log(notification.getNotDisplayedReason())
                } else if (notification.isSkippedMoment()) {
                  console.log(notification.getSkippedReason())
                } else if (notification.isDismissedMoment()) {
                  console.log(notification.getDismissedReason())
                } else {
                  console.log('unsupported google login display', notification.getMomentType())
                }
              })
            } else {
              // console.log('no google', tries)

              launchOneTap()
            }
          }, 100)
        }

        launchOneTap()
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > headerOptions.defaultScroll) {
      if (winScroll > headerOptions.scroll && headerOptions.isVisible) {
        headerOptions.isVisible = false
      } else if (winScroll < headerOptions.scroll && !headerOptions.isVisible) {
        headerOptions.isVisible = true
      }

      headerOptions.scroll = winScroll
      setHeaderOptions({ ...headerOptions })
    }
  }

  useEffect(() => {
    if (window) {
      const { pathname } = window.location
      if (pathname && pathname.indexOf('/insights') === 0) {
        setIsSearchShown(true)
      }
    }

    getLoggedInUser()

    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  return (
    <Fragment>
      <StyledFlex isVisible={headerOptions.isVisible}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          mx="auto"
          px="l"
        >
          <HomeLink href="/">
            <Flex
              alignItems="center"
              maxWidth={{ mobile: '210px', tablet: '165px', desktopWide: '205px' }}
              justifyContent="space-between"
            >
              <LocalImage
                src={logoBig}
                maxWidth={{ mobile: '180px', tablet: '135px', desktopWide: '180px' }}
                alt="Vista Social"
              />
            </Flex>
          </HomeLink>
          <Flex display={{ mobile: 'none', tablet: 'flex' }} justifyContent="center" alignItems="center" height="50px">
            {MENU_LINKS.map(({ label, to, children }) => (
              <MenuLinkWrapper key={to}>
                <StyledLink
                  fontSize="s"
                  py="s"
                  px={{ tablet: 's', desktopWide: 'm' }}
                  as={children ? 'p' : 'a'}
                  href={children ? '' : to}
                  color={COLOR_CONSTANTS.BLACK}
                >
                  {label}
                  {children && (
                    <Fragment>
                      <StyledImageChevronRightSalute
                        src={ChevronRightSalute}
                        className="arrow_down_salute"
                        width="12px"
                        height="12px"
                        mt="1px"
                        ml="xs"
                      />
                      <StyledImageChevronRightCobalt
                        src={ChevronRightCobalt}
                        className="arrow_down_cobalt"
                        width="12px"
                        height="12px"
                        mt="1px"
                        ml="xs"
                      />
                    </Fragment>
                  )}
                </StyledLink>

                {children && (
                  <MenuChildrenWrapper>
                    {children.map(({ label, to, icon, description }) => (
                      <MenuChildrenStyledLink py="s" px="m" as="a" key={to} href={to}>
                        <Flex>
                          {icon && <LocalImage src={icon} width="24px" height="24px" mt="xs" />}
                          <Flex ml={icon ? 'm' : 's'} flexDirection="column">
                            <Text as="span" fontSize="s" color={COLOR_CONSTANTS.BLACK} fontWeight="medium">
                              {label}
                            </Text>
                            {description && (
                              <Text as="span" fontSize="xs" color="secondaryText">
                                {description}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      </MenuChildrenStyledLink>
                    ))}
                  </MenuChildrenWrapper>
                )}
              </MenuLinkWrapper>
            ))}
          </Flex>

          <Flex alignItems="center">
            <Flex display={isSearchShown ? 'flex' : 'none'} mr="m">
              <BlogSearcherComponent />
            </Flex>
            <Flex display={{ mobile: 'flex', desktop: 'none' }} maxHeight="500px">
              <DropdownMenu
                WrapperComp={
                  <Flex>
                    <button type="button">
                      <img src={hamburger} width="28" alt="Menu" />
                    </button>
                  </Flex>
                }
                right={0}
              >
                <Flex flexDirection="column" height={{ mobile: '500px', tablet: '105px' }}>
                  <Scrollbars universal>
                    <Fragment>
                      {loggedIn ? (
                        <StyledContinueButton
                          as="a"
                          href="/dashboard"
                          isSmall={false}
                          width="310px"
                          height={DEFAULT_MENU_LINK_HEIGHT}
                        >
                          <Flex alignItems="center" justifyContent="space-between" width="100%">
                            <StyledContinueText mr="s">Continue as {userName}</StyledContinueText>
                            <LocalImage
                              src={imageDoubleArrowRightWhite}
                              className="arrow_white"
                              width="18px"
                              height="18px"
                            />
                            <StyledImageArrowBlue
                              src={imageDoubleArrowRightBlue}
                              className="arrow_blue"
                              width="18px"
                              height="18px"
                            />
                          </Flex>
                        </StyledContinueButton>
                      ) : (
                        <Fragment>
                          <StyledLink
                            as="a"
                            href="/login"
                            color={colors.primary}
                            fontWeight="medium"
                            p="m"
                            fontSize="m"
                            height={DEFAULT_MENU_LINK_HEIGHT}
                          >
                            Log In
                          </StyledLink>
                          <ButtonLinkDropdownMenu
                            className="header-login-button"
                            as="a"
                            href="/pricing"
                            p="m"
                            mr={{ mobile: 'm', tablet: 0 }}
                          >
                            <Text py="m" fontSize="m" fontWeight="medium">
                              Try it free
                            </Text>
                          </ButtonLinkDropdownMenu>
                        </Fragment>
                      )}
                    </Fragment>

                    <Flex flexDirection="column" display={{ mobile: 'flex', tablet: 'none' }}>
                      {MENU_LINKS.map(({ label, to, children }) => (
                        <Flex flexDirection="column" key={to}>
                          <StyledLink
                            p="m"
                            fontSize="m"
                            as={children ? 'p' : 'a'}
                            href={children ? '' : to}
                            color={COLOR_CONSTANTS.SALUTE}
                            fontWeight="medium"
                            height={DEFAULT_MENU_LINK_HEIGHT}
                          >
                            {label}
                            {children && (
                              <Fragment>
                                <StyledImageChevronRightSalute
                                  src={ChevronRightSalute}
                                  className="arrow_down_salute"
                                  width="12px"
                                  height="12px"
                                  mt="1px"
                                  ml="xs"
                                />
                                <StyledImageChevronRightCobalt
                                  src={ChevronRightCobalt}
                                  className="arrow_down_cobalt"
                                  width="12px"
                                  height="12px"
                                  mt="1px"
                                  ml="xs"
                                />
                              </Fragment>
                            )}
                          </StyledLink>
                          {children &&
                            children.map(({ label, to, icon }) => (
                              <StyledLink p="m" ml="m" as="a" key={to} href={to}>
                                <Flex alignItems="center">
                                  {icon && <LocalImage src={icon} width="24px" height="24px" />}

                                  <Flex ml={icon ? 'm' : 0} flexDirection="column">
                                    <Text fontSize="m" color={COLOR_CONSTANTS.BLACK} fontWeight="medium">
                                      {label}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </StyledLink>
                            ))}
                        </Flex>
                      ))}
                    </Flex>
                  </Scrollbars>
                </Flex>
              </DropdownMenu>
            </Flex>
            <Flex display={{ mobile: 'none', desktop: 'flex' }} justifyContent="flex-end" alignItems="center">
              <Fragment>
                {loggedIn ? (
                  <Flex justifyContent="flex-end" width="265px">
                    <StyledContinueButton as="a" href="/dashboard" isSmall={false} maxWidth="265px">
                      <Flex alignItems="center" justifyContent="space-between" maxWidth="230px">
                        <StyledContinueText mr="s">Continue as {userName}</StyledContinueText>
                        <LocalImage
                          src={imageDoubleArrowRightWhite}
                          className="arrow_white"
                          width="18px"
                          height="18px"
                        />
                        <StyledImageArrowBlue
                          src={imageDoubleArrowRightBlue}
                          className="arrow_blue"
                          width="18px"
                          height="18px"
                        />
                      </Flex>
                    </StyledContinueButton>
                  </Flex>
                ) : (
                  <Flex justifyContent="flex-end" width="265px">
                    <Button.Reversed as="a" href="/login" isSmall={false}>
                      <Text fontWeight="bold">Log In</Text>
                    </Button.Reversed>
                    <StyledButtonGradient as="a" href="/pricing" isSmall={false} className="header-login-button">
                      <Text>Try it free</Text>
                    </StyledButtonGradient>
                  </Flex>
                )}
              </Fragment>
            </Flex>
          </Flex>
        </Flex>
      </StyledFlex>
      {showGoogleSignedUpPopup && (
        <GoogleSignupPopup
          handleDismiss={() => {
            setShowGoogleSignedUpPopup(false)
          }}
        />
      )}
    </Fragment>
  )
}

Header.defaultProps = {}

Header.propTypes = {}

export default Header
